import {
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCol,
    IonContent, IonFab, IonFabButton,
    IonGrid,
    IonHeader,
    IonIcon,
    IonImg,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonNote,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonSlide,
    IonSlides,
    IonToolbar,
    useIonAlert,
    useIonModal,
    useIonToast,
    useIonViewWillEnter
} from '@ionic/react';
import './DeliveryDetail.scss';
import React, {useCallback, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import {Storage} from "@capacitor/storage";
import {
    alarmOutline,
    arrowBackOutline,
    camera,
    carOutline,
    chatboxOutline,
    checkmarkOutline,
    closeOutline,
    constructOutline,
    createOutline,
    documentOutline,
    informationCircleOutline,
    pinOutline,
    qrCodeOutline,
    refreshCircleOutline,
    repeatOutline, trashOutline
} from "ionicons/icons";
import {useFetch} from "../../hooks/use-fetch";
import {useSelector} from "react-redux";
import {AuthState} from "../../services/store";
import PhotoViewer from "../../modals/PhotoViewer";
import {Network} from "@capacitor/network";
import {Camera, CameraDirection, CameraResultType, CameraSource, ImageOptions} from "@capacitor/camera";
import IssueForm from './IssueForm';
import GeneralIssueForm from './GeneralIssueForm';

const DeliveryDetail: React.FC = () => {
    const {unique_key}: any = useParams();
    const {token} = useSelector((state: AuthState) => state.auth.user);
    const {loading, getRouteData, fetchData} = useFetch();
    const history = useHistory();
    const [selectedSegment, setSelectedSegment] = useState('delivery_list');
    const [issueDetails, setIssueDetails] = useState({branchlist: [], order_id: '', articlenumber: '', rowKey: '', prevRemarks: []});
    const [generalIssueDetails, setGeneralIssueDetails] = useState({title: '', order: '', branchlist: [], prevRemarks: []});
    const [photoDetails, setPhotoDetails] = useState({src: '', title: '', description: '', color: false});
    const [currentDetail, setCurrentDetail] = useState({
        name: '',
        address: '',
        postal_code: '',
        city: '',
        is_construction_site: 0,
        volume_data_html: '',
        orders: [],
        has_early: 0,
        has_return: 0,
        has_refold: 0,
    });
    const [fileList, setFileList] = useState([]);
    const [readOnly, setReadOnly] = useState(true);
    const [planner, setPlanner] = useState(false);
    const [general, setGeneral] = useState({branchlist: []});
    const [generalRemarks, setGeneralRemarks] = useState([]);
    const [articleRemarks, setArticleRemarks] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [imageList, setImageList] = useState([]);
    const [toast] = useIonToast();
    const [present] = useIonAlert();


    const handleDismiss = () => {
        dismissModal();
    }

    const [modal, dismissModal] = useIonModal(PhotoViewer, {
        onDismiss: handleDismiss,
        data: photoDetails
    });

    const handleGeneralIssueDismiss = () => {
        getRemarks();
        dismissGeneralIssueModal();
    }

    const [generalIssueModal, dismissGeneralIssueModal] = useIonModal(GeneralIssueForm, {
        onDismiss: handleGeneralIssueDismiss,
        data: generalIssueDetails
    });

    const handleIssueDismiss = () => {
        getRemarks();
        dismissIssueModal();
    }

    const [issueModal, dismissIssueModal] = useIonModal(IssueForm, {
        onDismiss: handleIssueDismiss,
        data: issueDetails
    });

    const prevPage = () => {
        if (history.location.pathname.startsWith('/tabs/delivery_today')) {
            history.replace(`/tabs/delivery_today`);
        } else if (history.location.pathname.startsWith('/tabs/delivery_planner')) {
            history.replace('/tabs/delivery_planner');
        }
    };

    const getData = useCallback(async (event?) => {
        const currentRouteId = await Storage.get({key: 'current_route'});
        const nextRouteId = await Storage.get({key: 'next_route'});
        if (currentRouteId.value && history.location.pathname.startsWith('/tabs/delivery_today')) {
            if (token) {
                const data = await getRouteData(JSON.stringify({token}));

                if (data) {
                    const toDeliver = await data.current_work_day[currentRouteId.value].delivery_data.to_deliver.find(item => item.unique_key === unique_key);
                    const delivered = await data.current_work_day[currentRouteId.value].delivery_data.delivered.find(item => item.unique_key === unique_key);

                    setGeneral(data.general);
                    if (toDeliver) {
                        setCurrentDetail(toDeliver);
                        setReadOnly(false);
                    } else if (delivered) {
                        setCurrentDetail(delivered);
                    } else {
                        history.replace('/');
                    }
                } else {
                    history.replace('/');
                }
            }
        } else if (nextRouteId.value && history.location.pathname.startsWith('/tabs/delivery_planner')) {
            const data = await getRouteData(JSON.stringify({token}));
            if (data) {
                const toLoad = await data.next_work_day[nextRouteId.value].delivery_data.find(item => item.unique_key === unique_key);

                if (toLoad) {
                    setCurrentDetail(toLoad);
                    setPlanner(true);
                } else {
                    history.replace('/');
                }
            } else {
                history.replace('/');
            }
        } else {
            history.replace('/');
        }

        if (event) {
            event.detail.complete();
        }
    }, [unique_key, history, getRouteData, token]);

    const getImages = useCallback(() => {
        Storage.get({key: 'images'}).then(async (res) => {
            if (res.value) {
                const today = new Date().getDate();
                const list = await JSON.parse(res.value);
                const currentDelivery = list.find((customer) => customer.unique_key === unique_key);
                if (currentDelivery) {
                    if (today === currentDelivery.dateToCheck) {
                        setImageList(currentDelivery.list);
                    } else {
                        await Storage.remove({key: 'images'});
                        setImageList([]);
                        await Storage.remove({key: 'article_remarks_key'});
                        setArticleRemarks([]);
                        await Storage.remove({key: 'general_remarks_key'});
                        setGeneralRemarks([]);
                    }
                }
            }
        });
    }, [unique_key]);

    const getRemarks = useCallback(() => {
        Storage.get({key: 'article_remarks_key'}).then(async (res) => {
            if (res.value) {
                const keys = await JSON.parse(res.value);
                setArticleRemarks(keys);
            }
        });
        Storage.get({key: 'general_remarks_key'}).then(async (res) => {
            if (res.value) {
                const keys = await JSON.parse(res.value);
                setGeneralRemarks(keys);
            }
        });
    }, [setArticleRemarks, setGeneralRemarks]);

    useIonViewWillEnter(() => {
        getData().then();
    });

    useEffect(() => {
        getData().then();
        getImages();
        getRemarks();
    }, [getRemarks, getImages, getData, token]);

    const openCameraPrompt = async () => {
        const cameraPrompt = await present({
            header: 'Maak een keuze',
            buttons: [
                {
                    text: 'Kies foto uit Bibliotheek',
                    handler: async () => {
                        const options: ImageOptions = {
                            quality: 50,
                            source: CameraSource.Photos,
                            resultType: CameraResultType.Base64
                        };

                        let imageData = null;

                        try {
                            imageData = await Camera.getPhoto(options);
                        } catch (e) {
                            if (e.message !== 'User cancelled photos app') {
                                throw new Error(e);
                            }
                        }

                        if (imageData) {
                            await usePicture(imageData);
                        }
                    }
                },
                {
                    text: 'Open camera',
                    handler: async () => {
                        const options: ImageOptions = {
                            quality: 50,
                            source: CameraSource.Camera,
                            direction: CameraDirection.Rear,
                            resultType: CameraResultType.Base64
                        };

                        let imageData = null;

                        try {
                            imageData = await Camera.getPhoto(options);
                        } catch (e) {
                            if (e.message !== 'User cancelled photos app') {
                                throw new Error(e);
                            }
                        }

                        if (imageData) {
                            await usePicture(imageData);
                        }
                    }
                },
                {
                    text: 'Annuleren',
                    role: 'cancel'
                }
            ]
        });
    }

    const usePicture = async (imageData) => {
        if (imageData) {
            const images = await Storage.get({key: 'images'});
            if (images.value) {
                const tmpArray = await JSON.parse(images.value);
                const currentDelivery = tmpArray.find((customer) => customer.unique_key === unique_key);
                if (currentDelivery) {
                    const index = tmpArray.indexOf(currentDelivery);
                    setImageList((prevList) => {
                        tmpArray[index] = {
                            unique_key,
                            dateToCheck: new Date().getDate(),
                            list: ['data:image/jpeg;base64,' + imageData.base64String, ...prevList]
                        };
                        return ['data:image/jpeg;base64,' + imageData.base64String, ...prevList];
                    });
                    await Storage.set({key: 'images', value: JSON.stringify(tmpArray)});
                } else {
                    setImageList((prevList) => {
                        Storage.set({
                            key: 'images',
                            value: JSON.stringify([{
                                unique_key,
                                dateToCheck: new Date().getDate(),
                                list: ['data:image/jpeg;base64,' + imageData.base64String, ...prevList]
                            }, ...tmpArray])
                        });
                        return ['data:image/jpeg;base64,' + imageData.base64String, ...prevList]
                    });
                }
            } else {
                setImageList((prevList) => {
                    Storage.set({
                        key: 'images',
                        value: JSON.stringify([{
                            unique_key,
                            dateToCheck: new Date().getDate(),
                            list: ['data:image/jpeg;base64,' + imageData.base64String, ...prevList]
                        }])
                    });
                    return ['data:image/jpeg;base64,' + imageData.base64String, ...prevList]
                });
            }
        }
    };

    const deleteImagePrompt = async (image, i) => {
        const warningPrompt = await present({
            header: 'Weet je zeker dat je de afbeelding wilt verwijderen?',
            message: 'Dit kan niet ongedaan worden gemaakt',
            buttons: [
                {
                    text: 'Ja',
                    handler: async () => {
                        const images = await Storage.get({key: 'images'});
                        if (images.value) {
                            const tmpArray = await JSON.parse(images.value);
                            const currentDelivery = tmpArray.find((customer) => customer.unique_key === unique_key);
                            if (currentDelivery) {
                                const index = tmpArray.indexOf(currentDelivery);

                                setImageList((prevList) => {
                                    const newList = [...prevList];
                                    newList.splice(i, 1);

                                    tmpArray[index] = {
                                        unique_key,
                                        dateToCheck: new Date().getDate(),
                                        list: newList
                                    }
                                    return newList;
                                });

                                await Storage.set({key: 'images', value: JSON.stringify(tmpArray)});
                            }
                        }
                    }
                },
                {
                    text: 'Nee',
                    role: 'cancel'
                }
            ]
        });
    };

    const openImage = async (image: string, title?: string) => {
        await setPhotoDetails({
            src: image,
            title: title ? title : '',
            description: '',
            color: false
        });
        modal();
    };

    const openIssue = async (articleNumber: string, description: string, orderId: string, rowKey: string) => {
        await setIssueDetails({
            branchlist: general.branchlist,
            order_id: orderId,
            articlenumber: articleNumber,
            rowKey: rowKey,
            prevRemarks: articleRemarks
        });
        issueModal();
    };

    const openGeneralIssue = async (order) => {
        await setGeneralIssueDetails({
            title: currentDetail.name,
            order: order,
            branchlist: general.branchlist,
            prevRemarks: generalRemarks
        });
        generalIssueModal();
    };

    const openQr = async () => {
        const net = await Network.getStatus();
        if (net.connected) {
            let tmpOrders = [];
            for (const order of currentDetail.orders) {
                tmpOrders.push(order.order_id);
            }
            const data = await fetchData('ZidaGenerateQrCodeShippingDoc', 'POST', JSON.stringify({
                token,
                order_ids: tmpOrders
            }));
            if (data.data) {
                await setPhotoDetails({
                    src: 'data:image/svg+xml; base64, ' + data.data,
                    title: '',
                    description: '',
                    color: true
                });
                modal();
            }
        } else {
            await offlineToast();
        }
    };

    const openFile = async (key) => {
        const net = await Network.getStatus();
        if (net.connected) {
            const content = await fetchData('ZidaGetPdfAttachment', 'POST', JSON.stringify({token, download_key: key}));
            const res = await content.data;
            if (res) {
                setFileList(res);
            } else {
                present(content.message);
            }
        } else {
            await offlineToast();
        }
    };

    const getIndex = (event) => {
        event.target.getActiveIndex().then((value) => {
            setCurrentPage(value + 1);
        });
    };

    const closeFile = async () => {
        setFileList([]);
        setCurrentPage(1);
    };

    const offlineToast = () => {
        toast({
            message: 'Je bent offline, probeer het later opnieuw',
            color: 'warning',
            duration: 2000
        });
    };

    const checkUpdateSpecificOrder = async (order_id: string) => {
        present({
            header: `Weet je zeker dat de order niet kan worden opgehaald?`,
            buttons: [
                {
                    text: 'Nee',
                    role: 'cancel'
                },
                {
                    text: 'Ja',
                    handler: async () => {
                        await updateSpecificOrder(order_id);
                    }
                }
            ]
        });
    };

    const updateSpecificOrder = async (order_id: string) => {
        const delivery = await Storage.get({key: 'pending_delivery'});
        let deliveryValue = [];
        if (delivery.value) {
            deliveryValue = JSON.parse(delivery.value);
        }

        const currentOrder = currentDetail.orders.find(item => item.order_id === order_id);

        const orderRemarks = {};
        for (const product of currentOrder.products) {
            const currentRemark = articleRemarks.find(item => item.id === currentOrder.order_id + product.row_key);
            if (currentRemark) {
                orderRemarks[product.row_key] = currentRemark
            }
        }

        Network.getStatus().then(async (net) => {
            if (net.connected) {
                await fetchData('ZidaConfirmDelivery', 'POST', JSON.stringify({
                    token,
                    order_id,
                    images: imageList,
                    remarks: {articles: orderRemarks, general: generalRemarks.find((item) => item.order === order_id)},
                    completed: false
                }));
                if (currentDetail.orders.length === 1) {
                    await prevPage();
                }
            } else {
                deliveryValue.push({token, order_id, images: imageList, remarks: {articles: orderRemarks, general: generalRemarks.find((item) => item.order === order_id)}, completed: false});
                await Storage.set({key: 'pending_delivery', value: JSON.stringify(deliveryValue)});
                await toast({
                    message: 'Je bent offline, de data zal verzonden worden als je weer online gaat.',
                    duration: 2000,
                    color: 'warning'
                });
                if (currentDetail.orders.length === 1) {
                    await prevPage();
                }
            }
        });
    };

    const checkUpdateManual = () => {
        updateAlert('geleverd', true);
    };

    const checkUpdate = async (returnOrder: number) => {
        if (returnOrder === 1) {
            updateAlert('opgehaald');
        } else if (imageList.length > 0) {
            updateAlert('geleverd');
        } else {
            toast({
                message: 'Er zijn nog geen afbeeldingen gemaakt',
                duration: 2000,
                color: 'warning'
            });
        }
    };

    const updateAlert = (type: string, manual?: boolean) => {
        present({
            header: `Weet je zeker dat je de order(s) wilt markeren als ${type}?`,
            buttons: [
                {
                    text: 'Nee',
                    role: 'cancel'
                },
                {
                    text: 'Ja',
                    handler: async () => {
                        if (manual) {
                            await updateDataManual();
                        } else {
                            await updateData();
                        }
                    }
                }
            ]
        });
    };

    const updateDataManual = async () => {
        const delivery = await Storage.get({key: 'pending_delivery'});
        let deliveryValue = [];
        if (delivery.value) {
            deliveryValue = JSON.parse(delivery.value);
        }

        for (const order of currentDetail.orders) {
            Network.getStatus().then(async (net) => {
                if (net.connected) {
                    await fetchData('ZidaConfirmDelivery', 'POST', JSON.stringify({
                        token,
                        delivery_id: order.levering_id,
                        images: imageList,
                        remarks: {},
                        completed: true
                    }));
                } else {
                    deliveryValue.push({token, delivery_id: order.levering_id, images: imageList, remarks: {}, completed: true});
                    await Storage.set({key: 'pending_delivery', value: JSON.stringify(deliveryValue)});
                    await toast({
                        message: 'Je bent offline, de data zal verzonden worden als je weer online gaat.',
                        duration: 2000,
                        color: 'warning'
                    })
                }
            });
        }
    };

    const updateData = async () => {
        const delivery = await Storage.get({key: 'pending_delivery'});
        let deliveryValue = [];
        if (delivery.value) {
            deliveryValue = JSON.parse(delivery.value);
        }

        for (const order of currentDetail.orders) {
            const orderRemarks = {};
            for (const product of order.products) {
                const currentRemark = articleRemarks.find(item => item.id === order.order_id + product.row_key);
                if (currentRemark) {
                    orderRemarks[product.row_key] = currentRemark
                }
            }

            Network.getStatus().then(async (net) => {
                if (net.connected) {
                    await fetchData('ZidaConfirmDelivery', 'POST', JSON.stringify({
                        token,
                        order_id: order.order_id,
                        images: imageList,
                        remarks: {articles: orderRemarks, general: generalRemarks.find((item) => item.order === order.order_id)},
                        completed: true
                    }));
                } else {
                    deliveryValue.push({token, order_id: order.order_id, images: imageList, remarks: {articles: orderRemarks, general: generalRemarks.find((item) => item.order === order.order_id)}, completed: true});
                    await Storage.set({key: 'pending_delivery', value: JSON.stringify(deliveryValue)});
                    await toast({
                        message: 'Je bent offline, de data zal verzonden worden als je weer online gaat.',
                        duration: 2000,
                        color: 'warning'
                    })
                }
                await prevPage();
            });
        }
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        {fileList.length > 0 &&
                        <IonButton onClick={closeFile}><IonIcon slot="icon-only" icon={closeOutline}/></IonButton>}
                        {fileList.length < 1 &&
                        <IonButton onClick={prevPage}><IonIcon slot="icon-only" icon={arrowBackOutline}/></IonButton>}
                    </IonButtons>
                    <IonButtons slot="end">
                        {fileList.length < 1 && !readOnly && currentDetail.orders.length > 0 && currentDetail.orders[0].order_id && (
                            <IonButton routerLink={`/tabs/delivery_today/${unique_key}/${currentDetail.orders[0].order_id}`} color="secondary">Assemblage</IonButton>
                        )}
                        {fileList.length < 1 && !readOnly && (
                            <IonButton
                                onClick={() => (currentDetail.orders.length > 0 && currentDetail.orders[0].order_id ? checkUpdate(currentDetail.has_return) : checkUpdateManual())}>{currentDetail.has_return === 0 ? 'Leveren' : 'Opgehaald'}</IonButton>
                        )}
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                {fileList.length < 1 && (
                    <>
                        <IonRefresher onIonRefresh={getData} slot="fixed">
                            <IonRefresherContent/>
                        </IonRefresher>
                        <IonList className="ion-no-padding">
                            <IonItem lines="none">
                                <h3>{currentDetail.name}
                                    <IonNote> {currentDetail.orders.length > 0 && currentDetail.orders[0].deb_nummer}</IonNote>
                                </h3>
                                <IonButtons slot="end">
                                    <IonButton onClick={openQr}>
                                        <IonIcon slot="icon-only" icon={qrCodeOutline}/>
                                    </IonButton>
                                </IonButtons>
                            </IonItem>
                            <IonItem lines="none">
                                <IonIcon icon={pinOutline} slot="start"/>
                                <IonLabel className="ion-text-wrap">
                                    <p>{currentDetail.address}</p>
                                    <p>{currentDetail.postal_code} {currentDetail.city}</p>
                                </IonLabel>
                                {currentDetail.is_construction_site === 1 && (
                                    <IonIcon slot="end" color="danger" icon={constructOutline}/>
                                )}
                            </IonItem>
                            {currentDetail.orders.length > 0 && currentDetail.orders[0].shipping && currentDetail.orders[0].shipping.shipping_company2 && (
                                <IonItem lines="none">
                                    <IonIcon icon={informationCircleOutline} slot="start"/>
                                    <IonLabel className="ion-text-wrap">
                                        <p><span dangerouslySetInnerHTML={{ __html: currentDetail.orders[0].shipping.shipping_company2 }}></span></p>
                                    </IonLabel>
                                </IonItem>
                            )}
                            {currentDetail.orders.length > 0 && currentDetail.orders[0].shipping && currentDetail.orders[0].shipping.additional_remarks_general && (
                                <IonItem lines="none">
                                    <IonIcon icon={chatboxOutline} slot="start"/>
                                    <IonLabel className="ion-text-wrap">
                                        <p><span dangerouslySetInnerHTML={{ __html: currentDetail.orders[0].shipping.additional_remarks_general }}></span></p>
                                    </IonLabel>
                                </IonItem>
                            )}
                            {currentDetail.orders.length > 0 && currentDetail.orders[0].shipping && currentDetail.orders[0].shipping.additional_remarks_transport && (
                                <IonItem lines="none">
                                    <IonIcon icon={carOutline} slot="start"/>
                                    <IonLabel className="ion-text-wrap">
                                        <p><span dangerouslySetInnerHTML={{ __html: currentDetail.orders[0].shipping.additional_remarks_transport }}></span></p>
                                    </IonLabel>
                                </IonItem>
                            )}
                            {(currentDetail.has_early === 1 || currentDetail.has_refold === 1 || currentDetail.has_return === 1 || currentDetail.is_construction_site === 1 || currentDetail.volume_data_html) && (
                              <IonItem lines="none">
                                  {currentDetail.has_early === 1 && <IonIcon icon={alarmOutline} color="danger" style={{marginRight: '10px'}}/>}
                                  {currentDetail.has_refold === 1 && <IonIcon icon={repeatOutline} color="danger" style={{marginRight: '10px'}}/>}
                                  {currentDetail.has_return === 1 && <IonIcon icon={refreshCircleOutline} color="danger" style={{marginRight: '10px'}}/>}
                                  {currentDetail.is_construction_site === 1 && <IonIcon icon={constructOutline} color="danger" style={{marginRight: '10px'}}/>}
                                  {currentDetail.volume_data_html && <span dangerouslySetInnerHTML={{__html: currentDetail.volume_data_html}}></span>}
                              </IonItem>
                            )}
                        </IonList>
                        {currentDetail.orders.length > 0 && currentDetail.orders[0].order_id && (
                            <IonSegment value={selectedSegment}
                                        onIonChange={(event) => setSelectedSegment(event.detail.value)}>
                                <IonSegmentButton value="delivery_list">
                                    Orderregels
                                </IonSegmentButton>
                                <IonSegmentButton value="delivery_files">
                                    Bijlagen
                                </IonSegmentButton>
                                <IonSegmentButton value="delivery_images">
                                    Foto's
                                </IonSegmentButton>
                            </IonSegment>
                        )}
                        <IonList className="ion-no-padding">
                            {(selectedSegment === 'delivery_list' || selectedSegment === 'delivery_files') && currentDetail.orders.map((order) => (
                                <div key={order.order_id ? order.order_id : order.levering_id}>
                                    <IonItem color='dark' lines="full">
                                        <h3>{order.order_id ? order.order_id : order.levering_id}</h3>
                                        {order.shipping && order.shipping.pickup_branche && order.customer && order.customer.company && (
                                            <h4 className="ion-margin-start">{order.customer.company}</h4>
                                        )}
                                        {order.shipping && order.shipping.return_order && (
                                            <IonIcon color="danger" slot="end" icon={refreshCircleOutline}/>
                                        )}
                                        {order.general && order.general.has_refold && (
                                            <IonIcon color="danger" slot="end" icon={repeatOutline}/>
                                        )}
                                        {order.vroeg && order.vroeg === 1 && (
                                            <IonIcon color="danger" slot="end" icon={alarmOutline}/>
                                        )}
                                        {order.order_id && order.shipping && order.shipping.return_order && (
                                            <IonButton onClick={() => checkUpdateSpecificOrder(order.order_id)}
                                                       color="danger" slot="end">Niet gelukt</IonButton>
                                        )}
                                        {!readOnly && (
                                          <IonButton color={"dark"} onClick={() => openGeneralIssue(order.order_id ? order.order_id : order.levering_id)} slot={"end"}>
                                              <IonIcon slot="icon-only" icon={createOutline}/>
                                          </IonButton>
                                        )}
                                    </IonItem>
                                    {generalRemarks.find((item) => item.order === order.order_id) && generalRemarks.find((item) => item.order === order.order_id).remarks && (
                                      <IonItem color={"secondary"} lines="none">
                                          <IonIcon icon={chatboxOutline} slot="start"/>
                                          <IonLabel className="ion-text-wrap">
                                              <p><span>{atob(generalRemarks.find((item) => item.order === order.order_id).remarks)}</span></p>
                                          </IonLabel>
                                      </IonItem>
                                    )}
                                    {readOnly && planner && (
                                        order.input_iso !== '0' ||
                                        order.input_pd !== '0' ||
                                        order.input_m !== '0' ||
                                        order.input_rz !== '0' ||
                                        order.input_pp !== '0' ||
                                        order.input_pl !== '0' ||
                                        order.input_np !== '0' ||
                                        order.input_h !== '0' ||
                                        order.input_hl !== '0' ||
                                        order.input_d !== '0'
                                    ) && (
                                        <IonItem>
                                            <IonLabel className="ion-text-wrap">
                                                <p style={{display: 'flex', flexWrap: 'wrap'}}>
                                                    {order.input_iso !== '0' && <span style={{marginRight: '5px', whiteSpace: 'nowrap'}}><b>ISO</b> {order.input_iso}</span>}
                                                    {order.input_pd !== '0' && <span style={{marginRight: '5px', whiteSpace: 'nowrap'}}><b>PD</b> {order.input_pd}</span>}
                                                    {order.input_m !== '0' && <span style={{marginRight: '5px', whiteSpace: 'nowrap'}}><b>M</b> <IonIcon color="success" icon={checkmarkOutline}/></span>}
                                                    {order.input_rz !== '0' && <span style={{marginRight: '5px', whiteSpace: 'nowrap'}}><b>RZ</b> {order.input_rz}</span>}
                                                    {order.input_pp !== '0' && <span style={{marginRight: '5px', whiteSpace: 'nowrap'}}><b>PP</b> {order.input_pp}</span>}
                                                    {order.input_pl !== '0' && <span style={{marginRight: '5px', whiteSpace: 'nowrap'}}><b>PL</b> {order.input_pl}</span>}
                                                    {order.input_np !== '0' && <span style={{marginRight: '5px', whiteSpace: 'nowrap'}}><b>NP</b> {order.input_np}</span>}
                                                    {order.input_h !== '0' && <span style={{marginRight: '5px', whiteSpace: 'nowrap'}}><b>H</b> <IonIcon color="success" icon={checkmarkOutline}/></span>}
                                                    {order.input_hl !== '0' && <span style={{marginRight: '5px', whiteSpace: 'nowrap'}}><b>HL</b> <IonIcon color="success" icon={checkmarkOutline}/></span>}
                                                    {order.input_d !== '0' && <span style={{marginRight: '5px', whiteSpace: 'nowrap'}}><b>D</b> <IonIcon color="success" icon={checkmarkOutline}/></span>}
                                                </p>
                                            </IonLabel>
                                        </IonItem>
                                    )}
                                    {!planner && order.loading && order.loading.volume_data_html !== '' && (
                                        <IonItem>
                                            <IonLabel className="ion-text-wrap">
                                                <p dangerouslySetInnerHTML={{__html: order.loading.volume_data_html}}/>
                                            </IonLabel>
                                        </IonItem>
                                    )}
                                    {selectedSegment === 'delivery_list' && order.products.map((product, index) => (
                                        <IonItem key={product.row_key} color={index % 2 === 0 ? 'light' : ''}>
                                            <IonBadge className="ion-margin-vertical ion-align-self-start"
                                                      style={{minWidth: '45px'}}
                                                      slot="start">{product.amount}<br/>{product.unit_label}
                                            </IonBadge>
                                            <IonLabel className="ion-text-wrap">
                                                <p style={{color: '#000'}}><b>{product.articlenumber}</b></p>
                                                <p style={{color: '#000'}}>{product.description}</p>
                                                <p>
                                                    <IonNote color="danger">
                                                        {articleRemarks.find(item => item.id === order.order_id + product.row_key) &&
                                                            atob(articleRemarks.find(item => item.id === order.order_id + product.row_key).remarks)
                                                        }
                                                    </IonNote>
                                                </p>
                                                {product.amount_text &&
                                                  <IonBadge color="medium">{product.amount_text}</IonBadge>}
                                            </IonLabel>
                                            {!readOnly && (
                                                <IonButtons slot="end">
                                                    <IonButton
                                                      onClick={() => openIssue(product.articlenumber, product.description, order.order_id, product.row_key)}>
                                                        <IonIcon slot="icon-only" icon={createOutline}/>
                                                    </IonButton>
                                                </IonButtons>
                                            )}
                                        </IonItem>
                                    ))}
                                  {selectedSegment === 'delivery_files' && (
                                    <>
                                      {order.attachments.map((attachment, index) => (
                                        <IonItem button onClick={() => openFile(attachment.download_key)}
                                                 key={attachment.download_key}
                                                 color={index % 2 === 0 ? 'light' : ''}>
                                          <IonIcon slot="start" icon={documentOutline}/>
                                          <IonLabel>
                                            <p>{attachment.filename}</p>
                                          </IonLabel>
                                        </IonItem>
                                      ))}
                                      <IonRow>
                                        {order.images.map((image, i) => (
                                          <IonCol size="6" key={i}>
                                            <IonImg src={'data:image/jpeg;base64,' + image.base64_image}
                                                    onClick={() => openImage('data:image/jpeg;base64,' + image.base64_image, image.image_title)}/>
                                            <IonLabel className="ion-text-center">
                                              <p>{image.image_title}</p>
                                            </IonLabel>
                                          </IonCol>
                                        ))}
                                      </IonRow>
                                    </>
                                  )}
                                </div>
                            ))}
                            {selectedSegment === 'delivery_images' && (
                                <>
                                    {!readOnly && (
                                        <IonButton class="ion-margin" shape="round" fill="clear" expand="block" color="primary" onClick={openCameraPrompt} style={{fontSize: '24px', lineHeight: '30px'}}>
                                            <IonIcon slot="icon-only" icon={camera}/>
                                        </IonButton>
                                    )}
                                    <IonGrid>
                                        <IonRow>
                                            {imageList.map((image, i) => (
                                                <IonCol size="6" key={i}>
                                                    <IonFab vertical={"top"} horizontal={"end"}>
                                                        <IonFabButton onClick={() => deleteImagePrompt(image, i)} size={"small"} color={"danger"}>
                                                            <IonIcon icon={trashOutline}></IonIcon>
                                                        </IonFabButton>
                                                    </IonFab>
                                                    <IonImg src={image} onClick={() => openImage(image)}/>
                                                </IonCol>
                                            ))}
                                        </IonRow>
                                    </IonGrid>
                                </>
                            )}
                        </IonList>
                    </>
                )}
                {fileList.length > 0 && (
                    <IonCard>
                        <IonItem lines="full">
                            <IonLabel>
                                Pagina {currentPage} van {fileList.length}
                            </IonLabel>
                        </IonItem>
                        <IonSlides id='slider' onIonSlideDidChange={(event) => getIndex(event)}>
                            {fileList.map((file) => (
                                <IonSlide key={file}>
                                    <img src={'data:image/jpeg; base64,' + file} alt="Afbeelding"/>
                                </IonSlide>
                            ))}
                        </IonSlides>
                    </IonCard>
                )}
            </IonContent>
            {loading && <IonLoading isOpen/>}
        </IonPage>
    );
};

export default DeliveryDetail;
